jQuery.fn.sortElements = (function () {

  var sort = [].sort;

  return function (comparator, getSortable) {

    getSortable = getSortable || function () {
        return this;
      };

    var placements = this.map(function () {

      var sortElement = getSortable.call(this),
        parentNode = sortElement.parentNode,

        // Since the element itself will change position, we have
        // to have some way of storing it's original position in
        // the DOM. The easiest way is to have a 'flag' node:
        nextSibling = parentNode.insertBefore(
          document.createTextNode(''),
          sortElement.nextSibling
        );

      return function () {

        if (parentNode === this) {
          throw new Error(
            "You can't sort elements if any one is a descendant of another."
          );
        }

        // Insert before flag:
        parentNode.insertBefore(this, nextSibling);
        // Remove flag:
        parentNode.removeChild(nextSibling);

      };

    });

    return sort.call(this, comparator).each(function (i) {
      placements[i].call(getSortable.call(this));
    });

  };

})();
jQuery.fn.addTableListener = (function () {
  return function () {
    $(this).find('.add-product-button').on('click', function () {
      var data = {};
      var cartButton = $(this);
      var totalCount = 0;
      var error = false;
      cartButton.parents('tr').find('.combination').each(function () {
        var input = $(this).find('input');
        var pack = input.attr('data-pack');
        var productId = input.attr('data-product-id');
        if ($(input).val() > 0) {
          var count = parseInt(input.val());
          if ($().checkNumberInRange(input)) {
            error = true;
            $(input).addClass('jsValueError');
            $(input).removeClass('jsValueSet');
          } else {
            $(input).removeClass('jsValueError');
            $(input).addClass('jsValueSet');
          }
          data['product[' + productId + '][' + pack + ']'] = count;
          totalCount += count;
        } else if ($(input).val() == 0) {
          data['product[' + productId + '][' + pack + ']'] = 0;
          $(input).removeClass('jsValueSet jsValueError');
        }
      });
      if (!error) {
        cartButton.removeClass('product-button-cart').html('<i class="fa fa-refresh fa-spin"></i>');
        $().addProduct(data, cartButton);
      }
    })
  };
})();

jQuery.fn.addProduct = (function () {
  return function (data, options) {
    options = options || false
    $.ajax({
      type: "POST",
      async: true,
      url: "/shop/add-product",
      data: data,
      timeout: 10000,
      error: function () {
      },
      success: function () {
        if (options && $(options).find('.add-product-button')) {
          options.addClass('product-button-cart').html('');
        }
        $().updateCart();
      }
    });
  };
})();

jQuery.fn.checkNumberInRange = (function () {
  return function (input) {
    var count = parseInt($(input).val());
    var minValue = parseInt($(input).attr('data-min'));
    var maxValue = parseInt($(input).attr('data-max'));
    return typeof minValue != 'undefined' && count < minValue || count > maxValue;

  };
})();

jQuery.fn.addCartStatusListener = (function () {
  return function () {
    $('.pack-remove-link').on('click', function () {
      var removeButton = $(this);
      var productId = removeButton.attr('data-product-id');
      var pack = removeButton.attr('data-pack');
      removeButton.removeClass('fa-times').addClass('fa-refresh fa-spin');
      $.ajax({
        type: "POST",
        url: "/shop/remove-pack",
        data: {productId: productId, pack: pack},
        timeout: 10000,
        error: function () {
          removeButton.replaceWith("...");
        },
        success: function () {
          removeButton.parent().replaceWith('');
          $().updateCart();
          $().updateAmount();
          $('input[name="product['+productId+']['+pack+']"]').val('');
        }
      });
    });
  };

})();
jQuery.fn.updateAmount = (function () {
  return function () {
    var totalSumm = 0;
    $("#content-wrapper").find('.products-table .product-total .total-product-value').each(function () {
      totalSumm += parseFloat($(this).text());
    });
    totalSumm = totalSumm.toFixed(2);
    var tax = $('.tax span');
    if (tax.length) {
      var discountText = tax.text().trim();
      tax = parseFloat(discountText) || 0;
    }

    var taxAmount = totalSumm * (tax / 100);
    var discountPrice = +totalSumm + taxAmount;
    $.ajax({
      type: "POST",
      url: "/shop/freight",
      data:{
        'price': totalSumm
      } ,
      timeout: 10000,
      error: function () {

      },
      success: function (response) {
        $('.freight span').text(response);
        $('#order-total-summ').html(totalSumm);
        $('#final-price-container').html((parseFloat(response) + parseFloat(discountPrice)).toFixed(2));
        var minPrice = $('.cart-price-min').data('minprice');
        $('.cart-price-min .min_price').text(minPrice);
        if (discountPrice > + minPrice) {
          $('.cart-price-min').addClass('d-none');
          $('.place-order-block input').attr('disabled', false);
        } else {
          $('.place-order-block input').attr('disabled', true);
          $('.cart-price-min').removeClass('d-none');
        }
      }
    });
  };

})();
jQuery.fn.updateCart = (function () {
  return function () {
    $.ajax({
      type: "GET",
      url: "/shop/get-cart-status",
      timeout: 10000,
      error: function () {
      },
      success: function (response) {
        $('#cart-status').html(response);
        $().addCartStatusListener();
        console.log($('.cart-info-block .minicart-summary .amount .amount'))
      }
    });
  };

})();

jQuery.fn.recalculateProduct = (function () {
  return function (element) {
    $(element).each(function () {
      var productTotal = 0;
      $(this).closest('tr').find('.combination .combination-value').each(function () {
        var thisCombinationAmount = $.trim($(this).find('input[name*="product"]').val()) ? $.trim($(this).find('input[name*="product"]').val()) : 0;
        var thisPriceValue = parseFloat($(this).find('.sub-price .price-value').text());
        if (isNaN(thisPriceValue)) {
          thisPriceValue = 0;
        }
        productTotal += parseInt(thisCombinationAmount) * thisPriceValue;
      });
      var discount = $(this).closest('tr').find('.product-total .total-product-value').data('discount') ? $(this).closest('tr').find('.product-total .total-product-value').data('discount') : 0;
      $(this).closest('tr').find('.product-total .total-product-value').html( (((100 - discount) * productTotal) / 100).toFixed(2));
      if (discount) {
        if (productTotal) {
          $(this).closest('tr').find('.product-total .total-old-price').html(productTotal.toFixed(2));
          $(this).closest('tr').find('.product-total .discount-block').removeClass('d-none');
        } else {
          $('.total-old-price').addClass('hidden')
        }
      }
    });
  };

})();


(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        $('input[name=search]').on('keyup', function () {
          var search_wrapper = $('#search-wrapper');
          var content_wrapper = $('#content-wrapper');
          var str = $(this).val();

          if (str == "") {
            $(search_wrapper).html('');
            $(content_wrapper).removeClass('hidden');
          }
          else {
            $(content_wrapper).addClass('hidden');
            $(search_wrapper).html('LOADING...');
            $.ajax({
              type: "GET",
              url: "/shop/search",
              data: {
                str: str
              },
              timeout: 10000,
              error: function () {
                $(search_wrapper).html("<H2>Something went wrong! Try again later</H2>")
              },
              success: function (response) {
                $(search_wrapper).html(response);
                $(search_wrapper).addTableListener();
                $().recalculateProduct('#search-wrapper  input[name*="product"]');
              }
            });
          }
        });

        $('.btn-group').on('click', '.dropdown-menu > div.dropdown-product', function (e) {
          e.preventDefault();
          e.stopPropagation();
        });

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('.products-table').addTableListener();
        $().addCartStatusListener();
      }

    },
    'order_form_page': {
      init: function () {
        var tableSortBySrt = function (name, inverse) {
          var table = $('table');

          var th = $(table).find("[data-srt='" + name + "']");
          var thIndex = th.index();

          table.find('td').filter(function () {
            return $(this).index() === thIndex;

          }).sortElements(function (a, b) {
            if ($(a).attr('data-srt-value') == $(b).attr('data-srt-value'))
              return 0;

            return $(a).attr('data-srt-value') > $(b).attr('data-srt-value') ?
              inverse ? -1 : 1
              : inverse ? 1 : -1;

          }, function () {
            // parentNode is the element we want to move
            return this.parentNode;
          });
        };

        $('#sort-name-asc').click(function () {
          tableSortBySrt("name", false);
          $('#sort-name').html("Name asc")

        });
        $('#sort-name-desc').click(function () {
          tableSortBySrt("name", true);
          $('#sort-name').html("Name desc")
        });


        if ($('body').is('.order-form-page')) {
          $(document).on('blur', '.order-form-section form input[name*="product"], #search-wrapper  input[name*="product"]', function() {
            var value = parseInt($.trim($(this).val()));
            var productTotal = 0;
            if (value) {
              $(this).val(value);
            }
            else {
              $(this).val('');
            }
            var data = {};
            var error = false;
            $(this).closest('tr').find('.combination .combination-value').each(function () {
              var thisCombinationAmount = $.trim($(this).find('input[name*="product"]').val()) ? $.trim($(this).find('input[name*="product"]').val()) : 0;
              var thisPriceValue = parseFloat($(this).find('.sub-price .price-value').text());
              if (isNaN(thisPriceValue)) {
                thisPriceValue = 0;
              }

              productTotal += parseInt(thisCombinationAmount) * thisPriceValue;
              var input = $(this).find('input');
              var input_name = $(input).attr('name').match(/\d+/g);
              var product_id = input_name[0];
              var product_pack = input_name[1];
              data['product[' + product_id + '][' + product_pack + ']'] = thisCombinationAmount;
              if (thisCombinationAmount == 0) {
                $(input).find('.jsValueSet').toggleClass('jsValueSet');
                $(input).find('.jsValueError').toggleClass('jsValueError');
              } else {
                  if ($().checkNumberInRange($(input))) {
                    error = true;
                    $(input).addClass('jsValueError');
                  } else {
                    $(input).removeClass('jsValueError');
                  }
              }
            });
            if ($('#cart-submit').length != 0 && !error) {
             $().addProduct(data, false);
            }
            if (!error) {
              var discount = $(this).closest('tr').find('.product-total .total-product-value').data('discount') ? $(this).closest('tr').find('.product-total .total-product-value').data('discount') : 0
              $(this).closest('tr').find('.product-total .total-product-value').html( (((100 - discount) * productTotal) / 100).toFixed(2));
              if (discount) {
                if (productTotal) {
                  $(this).closest('tr').find('.product-total .discount-block').removeClass('d-none');
                  $(this).closest('tr').find('.product-total .total-old-price').html(productTotal.toFixed(2))
                } else {
                  $(this).closest('tr').find('.product-total .discount-block').addClass('d-none');
                }

              }
              $('#cart-submit').updateAmount();
            }
          });

          $('[data-target="#resetFieldsModal"]').click(function (e) {
            e.preventDefault();
          })
          $('.jsResetFields').click(function (el) {
            $('.order-form-section form input[name*="product"]').each(function () {
              $(this).val('');
              $('.order-form-section form .total-product-value').html('0.00');
            });

            var form_data = $(".jsValueSet");
            var elem = $(this);
            elem.addClass('disabled');

            $.ajax({
              type: "POST",
              url: "/shop/reset",
              data: form_data,
              timeout: 10000,
              error: function () {
                $("#cart-submit").html("<H2>Something went wrong! Try again later</H2>")
                $("#cart-submit-modal-body").html("<H2>Something went wrong! Try again later</H2>")
              },
              success: function (response) {
                if (response.length === 0) {
                  $(".minicart-summary").html('0 item(s)<span class="label"></span>');
                  $(".cart-dropdown-menu").html('<div class="dropdown-item disabled no-items-selected">No products selected</div>');
                } else {
                  var response_json = JSON.parse(response);
                  elem.removeClass('disabled');
                  $('.minicart-summary .items-number').text(response_json.count);
                  $('.minicart-summary .amount .amount').text(response_json.price);
                }
                form_data.each(function (element, el){
                  $(el).removeClass('jsValueSet');
                })
                $().updateCart();
                $('.order-form-section form .total-product-value').html('0.00');
                $('.cart-price-total #order-total-summ').text('0.00');
              }
            });
            el.preventDefault();
          });
          $().recalculateProduct('.order-form-section form input[name*="product"]');

          $('#cart-submit .place-order-block button,#cart-submit .place-order-block.cart-buttons input[type="submit"]').click(function (e) {
            var form_data = $("#cart-submit").serialize();
            var elem = $(this);
            elem.addClass('disabled');

            $.ajax({
              type: "POST",
              url: "/shop/confirm",
              data: form_data,
              timeout: 10000,
              error: function () {
                $("#cart-submit").html("<H2>Something went wrong! Try again later</H2>")
                $("#cart-submit-modal-body").html("<H2>Something went wrong! Try again later</H2>")
              },
              success: function (response) {
                $("#cart-submit").html(response);
                $(".minicart-summary").html('0 item(s)<span class="label"></span>');
                $(".cart-dropdown-menu").html('<div class="dropdown-item disabled no-items-selected">No products selected</div>');
              }
            });

            e.preventDefault();
          });
        }
        $('#cart-submit').updateAmount();

      }
    },
    'ftp_browser_page': {
      init: function () {
        $('.jsSelectAll').click(function () {
          $('.jsSelectAll').prop('checked', $(this).prop('checked'));
          $('.catalog-table-section input[type="checkbox"]').prop('checked', $(this).prop('checked'));
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

$(document).ready(function() {
  $('#cart-submit').keydown(function (event) {
    if (event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  })

  $('#comment').on('input', function() {
    $(this).css("height", "auto");
    $(this).css("height", this.scrollHeight + "px");
  });
})